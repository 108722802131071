<template>
    <v-card :elevation="5">
        <v-card-title>
            Companies
            <v-spacer></v-spacer>

            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            />
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="companies"
            :search="search"
            :loading="loading"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <Create
                        @companyCreated="pushCompany"
                        :data="item"
                        />
                </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <v-icon
                    small
                    class="mr-2"
                    @click="show(item)"
                >
                    mdi-eye
                </v-icon>
                <Update :data="item" @updatedCompany="updateList" />
              </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import Create from './Create'
import Update from './Update'

export default {
  components: {
    Create,
    Update
  },
  data () {
    return {
      companies: [],
      loading: true,
      search: '',
      headers: [
        {
          text: 'Contract Number',
          align: 'start',
          sortable: true,
          value: 'contract'
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'actions',
          value: 'actions',
          sortable: false
        }
      ],
      item: null,
      popups: {}
    }
  },

  mounted () {
    this.$axios.get('api/companies').then(response => {
      this.companies = response.data
      this.popups = this.companies.map(c => c.id)
      this.loading = false
    })
  },

  methods: {
    pushCompany (item) {
      this.companies.unshift(item)
    },
    show (item) {
      this.$router.push({
        name: 'companies.show',
        params: { contract: item.id }
      })
    },
    // update (item) {
    //   if (this.popups.item) {

    //   }
    // },
    update (item) {
      if (!this.popups[item]) {
        this.popups[item] = false
      } else {
        this.popups[item] = !this.popups[item]
      }

      return this.popups[item]
    },

    updateList (item) {
      this.companies.splice(this.companies.findIndex(o => o.id === item.id), 1, item)
    }

  }
}
</script>
