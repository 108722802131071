<template>
    <v-card :elevation="5">
        <v-card-title>
            Users
            <v-spacer></v-spacer>

            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            />
        </v-card-title>
        <list
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="search"
            routeShow="users.show"
            routeUpdate="users.update"
        ></list>
    </v-card>
</template>

<script>
// import Create from './Create'
// import Update from './Update'

import list from '../../components/ui/list.vue'

export default {
  components: {
    list
    // Create,
    // Update
  },
  data () {
    return {
      items: [],
      loading: true,
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'email',
          align: 'start',
          sortable: true,
          value: 'email'
        },
        {
          text: 'actions',
          value: 'actions',
          sortable: false
        }
      ],
      item: null
    }
  },

  mounted () {
    this.$axios.get('api/users').then(response => {
      this.items = response.data
      this.loading = false
    })
  },

  methods: {
    pushItem (item) {
      this.items.unshift(item)
    },
    show (item) {
      this.$router.push({
        name: 'users.show',
        params: { id: item.id }
      })
    },
    // update (item) {
    //   if (!this.popups[item]) {
    //     this.popups[item] = false
    //   } else {
    //     this.popups[item] = !this.popups[item]
    //   }

    //   return this.popups[item]
    // },

    updateList (item) {
      this.items.splice(this.items.findIndex(o => o.id === item.id), 1, item)
    }

  }
}
</script>
