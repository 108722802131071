<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    :to="{ name: 'players.create' }"
                >
                    New Player
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="details(item)"
            >
                mdi-eye
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="update(item)"
                v-if="['active', 'upcoming'].includes(item.status)"
            >
                mdi-wrench
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
  name: 'list',
  props: {
    items: Array,
    headers: Array,
    loading: Boolean,
    search: String,
    routeShow: String,
    routeUpdate: String
  },

  data () {
    return {
      item: null,
      searchItem: null,
      select: null,
      selectedItems: []
    }
  },

  methods: {
    details (item) {
      this.$router.push({
        name: this.routeShow,
        params: { id: item.id }
      })
    },
    update (item) {
      this.$router.push({
        name: this.routeUpdate,
        params: { id: item.id }
      })
    }
  }
}
</script>
